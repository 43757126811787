<template>
  <b-card>
    <!-- form -->
    <validation-observer ref="languageMasterValidation">
      <b-form>
        <b-row>
          <b-col>
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="secondary"
              @click="$router.go(-1)"
            >
            <feather-icon icon="SkipBackIcon" />  {{ $t("Common.Back") }}
            </b-button>
          </b-col>
        </b-row>
        <b-row class="mt-2">
          <b-col md="6"> 
            <b-form-group :label="$t('Language List.LANGUAGE')">
              <validation-provider
                #default="{ errors }"
                name="Language Name"
                rules="required"
              >
                <b-form-input
                  v-model="form.language_name"
                  :state="errors.length > 0 ? false : null"
                  type="text"
                  :placeholder="$t('Language List.LANGUAGE')"
                  :disabled="!$can('write', 'masters')"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group> 
          </b-col>
          <b-col md="6">
            <b-form-group :label="$t('Language List.LANGUAGE CODE')">
              <validation-provider
                #default="{ errors }"
                name="Language Code"
                rules="required"
              >
                <b-form-input 
                  v-model="form.language_code"
                  :state="errors.length > 0 ? false : null"
                  type="text"
                  :placeholder="$t('Language List.LANGUAGE CODE')"
                  :disabled="!$can('write', 'masters')"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <b-col cols="12" class="text-center">
            <b-button
              variant="primary"
              type="submit" 
              @click.prevent="saveLanguage"
            >
              {{ $t("Common.Save") }}
            </b-button>
          </b-col>
        </b-row>
      </b-form>
    </validation-observer>
  </b-card>
</template>

<script>
import { ValidationProvider, ValidationObserver } from "vee-validate";
import {
  BCard,
  BFormInput,
  BFormGroup,
  BForm,
  BRow,
  BCol,
  BButton,
  BCardText,
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import { required } from "@validations";
import MasterServices from "@/apiServices/MasterServices";

export default {
  name: "form",
    directives: {
    Ripple,
  },
  components: {
    ValidationProvider,
    ValidationObserver,
    BCard,
    BCardText,
    BFormInput,
    BFormGroup,
    BForm,
    BRow,
    BCol,
    BButton,
  },
  data() {
    return {
      language_id: null,
      form: {
        language_name: "",
        language_code: "",
        language_is_active: true,
      },
      required,
    };
  },
  methods: {
    saveLanguage() {
      this.$refs.languageMasterValidation.validate().then(async (success) => {
        if (success) {
          try {
            this.form.language_id = this.language_id;
            // console.log("this.form=>",this.form);return false;
            const languageMaster = await MasterServices.saveLanguage(
              this.form
            );
            
            if (languageMaster.data.status) {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title:
                    languageMaster.data.message || "Language Added Successfull",
                  icon: "EditIcon",
                  variant: "success",
                },
              });
              this.$router.replace("/masters/language-list");
            } else {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: languageMaster.data.message || "Cannot Added Language",
                  icon: "EditIcon",
                  variant: "failure",
                },
              });
            }
          } catch (error) {
            console.log("Error in saveLanguage ", error);
            console.log({ error });
            this.$toast({
              component: ToastificationContent,
              props: {
                title: error.data.message || "Error in saveLanguage",
                icon: "EditIcon",
                variant: "failure",
              },
            });
          }
        }
      });
    },
    async getSingleLanguage(language_id) {
      try {
        const response = await MasterServices.getSingleLanguage(language_id);
        let singleLanguage = response.data.data;
        if (response.data.status) {
          this.form = {
            language_name: singleLanguage.language_name,
            language_code: singleLanguage.language_code,
            language_is_active: singleLanguage.language_is_active,
          };
        }
      } catch (err) {
        console.log("Error in getting  ", err);
      }
    },
  },
  beforeMount() {
    // console.log(this.$route.params);
    if (this.$route.params.language_id && !isNaN(this.$route.params.language_id)) {
      this.language_id = this.$route.params.language_id;
      this.getSingleLanguage(this.language_id);
    }
  },
};
</script>

<style></style>
